.not-found {
  height: 100%;
  padding: 50px 180px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
  cursor: default;
  text-align: center;
}

.not-found-heading {
  color: var(--white);
  font-weight: 700;
  font-size: 2rem;
  margin-bottom: 10px;
  z-index: 1;
}

.progress {
  width: 134.4px;
  height: 24.6px;
  border-radius: 22.4px;
  color: #fc604f;
  border: 2.2px solid;
  position: relative;
}

.progress::before {
  content: "";
  position: absolute;
  margin: 2.2px;
  inset: 0 100% 0 0;
  border-radius: inherit;
  background: currentColor;
  animation: progress-pf82op 3s infinite;
}

@keyframes progress-pf82op {
  100% {
    inset: 0;
  }
}
