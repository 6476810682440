*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  box-sizing: border-box;
}

:root {
  font-size: 16px;
  height: 100%;

  --red: #fc604f;
  --red-pressed: #fb331d;
  --green: #4ffc60;
  --bg-blue: #283e4c;
  --bg-purple: #292e4c;
  --light-blue: #356788;
  --black: #151515;
  --white: #f5f5f5;
}

#root {
  width: 100vw;
  min-height: 100vh;
  height: fit-content;
  position: relative;
  display: grid;
  grid-template-rows: 60px 1fr 35px;
}

a {
  text-decoration: none;
  color: inherit;
  cursor: default;
}

a:-webkit-any-link {
  cursor: default;
}

body {
  background: var(--black);
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
