.footer {
  position: sticky;
  bottom: 0;
  grid-row: 3 / 4;
  background: var(--black);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
