.level {
  position: relative;
  width: 100vw;
  height: fit-content;
}

.level-image {
  position: relative;
  width: 100%;
  cursor: url(../../assets/cursor.png) 40 40, auto;
}

.guess-shape {
  background: rgba(255, 255, 255, 0.15);
  border: 2px dashed var(--red);
  border-radius: 50px;
  height: 100px;
  width: 100px;
}

.dropdown-items {
  width: 200px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  background: linear-gradient(180deg, #283e4c 0%, #292e4c 100%);
  border-radius: 18px;
  z-index: 1;
  transition: background-color 0.2s;
}

.dropdown-item {
  height: 60px;
  display: grid;
  grid-template-columns: 80px 1fr;
  gap: 20px;
  align-items: center;
  cursor: pointer;
  background-color: none;
  border-radius: 18px;
  transition: background-color 0.2s;
}

.dropdown-item:hover {
  background-color: var(--light-blue);
}

.dropdown-item > img {
  height: 40px;
  width: auto;
  justify-self: center;
}

.dropdown-item > div {
  color: var(--white);
  font-weight: 300;
  font-size: 1.1rem;
  justify-self: start;
}

.found-item {
  height: 100px;
  width: 100px;
  background-color: rgba(79, 252, 96, 0.3);
  border: 2px solid var(--green);
  border-radius: 18px;
  z-index: 1;
}

.found-alert,
.not-found-alert {
  position: fixed;
  top: 15%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 9px;
  font-size: 1.3rem;
  text-align: center;
  padding: 10px 30px;
  z-index: 1;
  cursor: default;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.found-alert {
  background-color: rgba(79, 252, 96, 0.8);
  color: var(--black);
}

.not-found-alert {
  background-color: rgba(252, 96, 79, 0.9);
  color: var(--white);
}

.found-alert.show,
.not-found-alert.show {
  opacity: 1;
}

.congratulations-bg {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 2;
  display: none;
}

.congratulations-bg.show {
  display: block;
}

.congratulations-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: default;
  padding: 30px 80px;
  border-radius: 18px;
  background: var(--bg-blue);
  display: none;
  flex-direction: column;
  z-index: 2;
}

.congratulations-container.show {
  display: flex;
}

.congratulations-text,
.congratulations-score {
  color: var(--white);
  font-weight: 700;
  text-align: center;
}

.congratulations-text {
  margin-bottom: 10px;
  font-size: 2.1rem;
}

.congratulations-score {
  margin: 0 50px;
  font-size: 1.9rem;
  margin-bottom: 30px;
}

.congratulations-score > span {
  color: var(--red);
}

.congratulations-label {
  color: var(--white);
  font-weight: 300;
  font-size: 1.1rem;
  margin-bottom: 8px;
}

.congratulations-label + input {
  height: 50px;
  border-radius: 12px;
  border: none;
  outline: none;
  background: var(--light-blue);
  padding: 5px 15px;
  color: var(--white);
  font-size: 1.1rem;
  margin-bottom: 30px;
}

.congratulations-label + input::placeholder {
  color: var(--white);
}

.congratulations-buttons {
  align-self: center;
  display: flex;
  align-items: center;
  gap: 20px;
  opacity: 1;
  transition: opacity 0.25s ease-in-out;
}

.congratulations-buttons.disabled {
  opacity: 0;
}

.congratulations-back-to-levels-button,
.congratulations-leaderboard-button {
  color: var(--white);
  background: none;
  border: none;
  font-size: 1rem;
  padding: 10px 30px;
  border-radius: 12px;
  cursor: pointer;
}

.congratulations-leaderboard-button {
  background-color: var(--red);
  font-weight: 700;
  transition: background-color 0.2s;
}

.congratulations-leaderboard-button:hover {
  background-color: var(--red-pressed);
}

.congratulations-back-to-levels-button {
  font-weight: 300;
  border: 0.5px solid var(--bg-blue);
  transition: border 0.2s;
}

.congratulations-back-to-levels-button:hover {
  border: 0.5px solid var(--white);
}
