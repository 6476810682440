.leaderboard {
  position: relative;
  width: 100vw;
  height: 100%;
  display: flex;
  justify-content: center;
  padding: 50px 180px;

  background-image: radial-gradient(#292e4c 1px, transparent 1px),
    radial-gradient(#292e4c 1px, transparent 1px);
  background-size: 20px 20px;
  background-position: 0 0, 10px 10px;
  background-color: #151515;
}

.leaderboard-container {
  background-color: var(--bg-blue);
  width: 100%;
  height: calc(100vh - 195px);
  z-index: 1;
  border-radius: 18px;
  padding: 30px;
  display: flex;
  flex-direction: column;
}

.leaderboard-heading {
  font-weight: 700;
  color: var(--white);
  font-size: 2.3rem;
  margin-bottom: 15px;
  cursor: default;
}

.play-this-level-button {
  align-self: flex-start;
  padding: 10px 40px;
  font-weight: 700;
  font-size: 1.1rem;
  color: var(--white);
  background-color: var(--red);
  border: none;
  border-radius: 12px;
  cursor: pointer;
  transition: background-color 0.2s;
  margin-bottom: 40px;
}

.play-this-level-button:hover {
  background-color: var(--red-pressed);
}

.leaderboard-levels-grid {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  width: 100%;
  gap: 6px;
  margin-bottom: 20px;
}

.leaderboard-levels-grid-item {
  width: 100%;
  color: var(--white);
  font-weight: 700;
  font-size: 0.8rem;
  text-align: center;
  background-color: var(--bg-blue);
  padding: 10px 0;
  border: 1px solid var(--white);
  border-radius: 12px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.leaderboard-levels-grid-item:hover {
  background-color: var(--light-blue);
}

.leaderboard-levels-grid-item.selected {
  background-color: var(--white);
  color: var(--bg-blue);
}

.leaderboard-columns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--white);
  font-weight: 700;
  padding-bottom: 8px;
  border-bottom: 2px solid var(--white);
  cursor: default;
  margin-bottom: 20px;
}

.name-column,
.score-name {
  padding-left: 25px;
}

.time-column {
  padding-right: 25px;
}

.score-time {
  padding-right: 42px;
}

.leaderboard-scores {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
}

.score-element {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--white);
  font-weight: 0.8rem;
  font-weight: 300;
  padding-bottom: 7px;
  border-bottom: 0.5px solid var(--white);
  cursor: default;
  margin-bottom: 10px;
}
