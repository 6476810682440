.header {
  position: fixed;
  min-height: 60px;
  top: 0;
  left: 0;
  right: 0;
  grid-area: 1 / 1 / 2 / 2;
  background: linear-gradient(180deg, #283e4c 0%, #292e4c 100%);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 10px 180px;
  z-index: 2;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
}

.leaderboard-header-button,
.about-header-button,
.header-back-to-level-button,
.header-timer {
  align-self: center;
  font-weight: 300;
  color: var(--white);
  transition: font-weight 0.2s;
}

.leaderboard-header-button:hover,
.about-header-button:hover,
.header-back-to-level-button:hover {
  font-weight: 700;
  cursor: pointer;
}

.leaderboard-header-button,
.header-back-to-level-button {
  justify-self: flex-start;
  text-align: start;
}

.leaderboard-header-button-about {
  font-weight: 300;
  color: var(--white);
  transition: font-weight 0.2s;
  justify-self: flex-end;
  text-align: end;
  align-self: center;
}

.leaderboard-header-button-about:hover {
  font-weight: 700;
  cursor: pointer;
}

.about-header-button,
.header-timer {
  justify-self: flex-end;
  text-align: end;
}

.header-timer {
  cursor: default;
}

.header-level-items {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
}

.header-level-item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.header-level-item > img {
  height: 35px;
  width: auto;
}

.header-level-item > div {
  color: var(--white);
  font-weight: 300;
  cursor: default;
}
