.logo {
  grid-column: 2 / 3;
  justify-self: center;
  align-self: center;
  font-family: "Cherry Bomb One", cursive;
  color: var(--white);
  font-size: 2.1rem;
  margin-top: -8px;
  cursor: default;
}
