.about {
  position: relative;
  width: 100vw;
  height: 100%;
  display: flex;
  justify-content: center;
  padding: 50px 180px;

  background-image: radial-gradient(#292e4c 1px, transparent 1px),
    radial-gradient(#292e4c 1px, transparent 1px);
  background-size: 20px 20px;
  background-position: 0 0, 10px 10px;
  background-color: #151515;
}

.about-container {
  background-color: var(--bg-blue);
  width: 100%;
  height: calc(100vh - 195px);
  z-index: 1;
  border-radius: 18px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: default;
}

.about-heading {
  font-weight: 700;
  color: var(--white);
  font-size: 2.3rem;
  margin-bottom: 15px;
  cursor: default;
}

.about-paragraph {
  color: var(--white);
  font-weight: 300;
  font-size: 1.1rem;
  margin-bottom: 15px;
}

.about-credentials {
  color: var(--white);
  font-weight: 700;
  font-size: 1.1rem;
  margin-bottom: 15px;
  transition: color 0.2s;
}

.about-credentials > a:hover {
  color: var(--red);
  cursor: pointer;
}
